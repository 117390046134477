import React from "react";
import TableComponent from "../table/Table";
import { useTranslation } from "react-i18next";
import { tableConfig } from "../table/tableConfig";
import WB_NID from "../../../../assets/WB&NID.jpg";
import ProcurementTable from "../table/ProcurementTable";
const Body = () => {
    const { t } = useTranslation();
    return (
        <div className='flex justify-center md:justify-start items-center px-5 pb-[5%] md:pr-14 md:px-14 lg:px-14 xl:px-32'>
            <div className='space-y-5'>
                <div className=' sm:px-0 sm:pe-0 items-start'>
                    <div className='flex-1 flex flex-col gap-5'>
                        <p className='text-6xl font-semibold text-[#225D71] text-center sm:text-left pb-5'>
                            {t("worldbank_introduction_title")}
                        </p>
                        <div className='flex flex-col w-full lg:flex-row gap-5'>
                            <div className='space-y-5 px-5 sm:px-0'>
                                <p>
                                    {t("worldbank_introduction_title_text_1")}
                                </p>
                                <p>
                                    {t("worldbank_introduction_title_text_2")}
                                </p>
                                <p>
                                    {t("worldbank_introduction_title_text_3")}
                                </p>
                                <p>
                                    {t("worldbank_introduction_title_text_4")}
                                </p>
                                <p>
                                    {t("worldbank_introduction_title_text_5")}
                                </p>
                            </div>
                            <div className='relative flex flex-col sm:max-w-[60%] mx-auto md:max-w-[45%] lg:min-w-[400px] lg:max-w-[55%] w-full h-fit'>
                                <img
                                    src={WB_NID}
                                    className='object-container w-full rounded-2xl overflow-clip'
                                />

                                <div className='w-fit'>
                                    <div className='absolute bottom-10 inset-x-0 mx-auto w-[250px] text-center gap-y-3 flex flex-col'>
                                        <a href='https://projects.worldbank.org/en/projects-operations/project-procurement/P179040'>
                                            <p className='bg-[#083347] hover:bg-[#47F4C7] text-white rounded-lg py-2'>
                                                Visit Project Detail{" "}
                                            </p>
                                        </a>

                                        <a href='https://projects.worldbank.org/en/projects-operations/document-detail/P179040?type=projects'>
                                            <p className='bg-[#083347] hover:bg-[#47F4C7] text-white rounded-lg py-2'>
                                                Visit Operation Details
                                            </p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='space-y-5 px-5 sm:px-0'>
                    <p className='text-5xl font-semibold text-[#225D71]  text-center sm:text-left pb-5'>
                        {t("worldbank_procurement_title")}
                    </p>
                    <p>{t("worldbank_procurement_text")}</p>
                    <p className='font-bold'>
                        The Procurement activities to be carried out under the
                        project include the following
                    </p>
                    <div className='overflow-x-scroll w-[90vw] sm:w-auto sm:overflow-x-auto'>
                        <ProcurementTable />
                    </div>
                    <div className='space-y-1'>
                        <p className='text-sm'>
                            *Please note that the procurement activities are not
                            final and it is not meant as a call for procurement
                            of goods and consulting services. It may change from
                            time to time.
                        </p>
                        <p className='text-sm'>
                            PS: Please also note that all RFPs will be
                            published, as per their schedule on World Bank
                            website as well as id.gov.et/tender
                        </p>
                    </div>
                </div>

                <div className='space-y-5 w-full sm:w-auto overflow-x-scroll sm:overflow-auto'>
                    <p className='text-5xl font-semibold text-[#225D71] px-5 sm:px-0 text-center sm:text-left'>
                        {t("worldbank_resources_link")}
                    </p>
                    <TableComponent data={tableConfig} />
                </div>
            </div>
        </div>
    );
};

export default Body;
