import bank from "../../../assets/Locations/banks.png";
import Dars from "../../../assets/Locations/Dars.png";
import MOR from "../../../assets/Locations/MOR.png";
import postoffice from "../../../assets/Locations/Post-office.png";
import palaceparking from "../../../assets/Locations/palace-parking.png";
import Tele from "../../../assets/Locations/Tele.png";
import crrsa from "../../../assets/Locations/CRRSA.png";
export const locationConfig = [
    {
        name: "EthioTele",
        img: Tele,
    },
    {
        name: "CRRSA",
        img: crrsa,
    },
    {
        name: "Ethiopost",
        img: postoffice,
    },
    {
        name: "Palace Parking",
        img: palaceparking,
    },
    {
        name: "Banks",
        img: bank,
    },
    {
        name: "MOR",
        img: MOR,
    },
    {
        name: "Dars",
        img: Dars,
    },

];
