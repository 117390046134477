import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import i18next from "i18next";
import title from "../../../../assets/full logo-fayda podcast-01.png";
import { useSelector } from "react-redux";
import { getGalleries } from "../../../../redux/selectors";
import play from "../../../../assets/play icon-01.png";
import play1 from "../../../../assets/play icon-01 copy.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
// import Test from "../../../../assets/newpage.png";
// import Test2 from "../../../../assets/test2.png";
import Test2 from "../../../../assets/enflish-01-01 copy.png";
import Test from "../../../../assets/amharic copy.png";

const Newpage = () => {
    const { t } = useTranslation();
    const [IsOpen, setIsOpen] = useState(false);
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });
    const [currentImage, setCurrentImage] = useState("");
    const [currentCaption, setCurrentCaption] = useState("");
    const imgRef = useRef(null);

    function toggleModal(imageUrl, caption) {
        setIsOpen(!IsOpen);
        setCurrentImage(imageUrl);
        setCurrentCaption(caption);
    }

    return (
        <>
            {IsOpen && (
                <div
                    className='fixed top-0 left-0 w-full h-full py-20 backdrop-blur-[2px] bg-black/80 bg-opacity-50 flex items-center justify-center z-50 transition-all duration-500'
                    onClick={toggleModal}
                >
                    <img
                        ref={imgRef}
                        src={currentImage}
                        className='h-full object-contain'
                        alt='Modal content'
                    />

                    <div
                        id='modal-content'
                        className='absolute bottom-20 py-20 px-5 '
                    >
                        <p className='text-white text-3xl z-50'>
                            {currentCaption}
                        </p>
                    </div>
                </div>
            )}
            {isDesktopOrLaptop ? (
                <div class='bg-Bg2 bg-top-20 py-10 px-20 flex w-full  bg-cover '>
                    <div class='flex flex-col w-[80%] h-full  ml-0 mr-16 '>
                        {i18next.language === "en" ? (
                            <img
                                onClick={() => {
                                    toggleModal(
                                        Test2,
                                        "Data Collection Principles"
                                    );
                                }}
                                src={Test2}
                                alt=''
                                class='w-full h-auto hover:cursor-pointer'
                            />
                        ) : i18next.language === "amh" ? (
                            <img
                                onClick={() => {
                                    toggleModal(
                                        Test,
                                        "Data Collection Principles"
                                    );
                                }}
                                src={Test}
                                alt=''
                                class='w-full h-auto  hover:cursor-pointer'
                            />
                        ) : null}{" "}
                    </div>
                    <div className='w-[40%] mx-4 mt-20 '>
                        <div className='flex gap-3 mb-10'>
                            <h1 className=' text-3xl  text-left font-bold text-green'>
                                {t("newpage-h1")}
                            </h1>{" "}
                            <h1 className=' text-3xl  text-left font-bold text-white'>
                                {" "}
                                {t("newpage-h2")}
                            </h1>
                        </div>
                        <p className='text-xl font-normal text-justify text-white '>
                            {t("newpage-p")}
                        </p>
                    </div>
                </div>
            ) : (
                <div class='bg-Bg2 bg-top-20 py-10  flex flex-col w-full bg-cover'>
                    <div class='flex flex-col w-full h-full '>
                        {i18next.language === "en" ? (
                            <img
                                src={Test2}
                                alt=''
                                class='w-full h-auto hover:cursor-pointer'
                            />
                        ) : i18next.language === "amh" ? (
                            <img
                                src={Test}
                                alt=''
                                class='w-full h-auto hover:cursor-pointer'
                            />
                        ) : null}{" "}
                    </div>
                    <div className='justify-ceneter  ml-10 '>
                        <div className='flex mb-5 ml-1 gap-1'>
                            <h1 className=' text-2xl text-left  font-bold text-green'>
                                {t("newpage-h1")}
                            </h1>
                            <h1 className=' text-2xl text-left font-bold text-white'>
                                {" "}
                                {t("newpage-h2")}
                            </h1>
                        </div>
                        <p className='text-xl pr-3 font-normal text-justify  text-white '>
                            {t("newpage-p")}
                        </p>
                    </div>
                </div>
            )}
        </>
    );
};

export default Newpage;
