import React, { useState, useContext, useEffect } from "react";
import Carousel from "./carousel";
import mobilevideo from "../../../../assets/mobile_video.mp4";
import { Helmet } from "react-helmet";
import { Slide } from "react-slideshow-image";
import { CountUp } from "use-count-up";
import { Animate, FadeIn } from "../../../../hooks/animations";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import { ViewContext } from "../../../../providers/viewContext";
import { useMediaQuery } from "react-responsive";
import v1 from "../../../../assets/part1.mp4";
import b from "../../../../assets/b-01.jpg";
import { Link, useLocation, useParams } from "react-router-dom";

// import v2 from "../../../../assets/part2.mp4";
// import v3 from "../../../../assets/part3.mp4";

const Hero = ({ citizen, heroHome }) => {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });

    const one = useMediaQuery({
        query: "(max-height: 300px)",
    });

    const TwoFiveZeroPercent = useMediaQuery({
        query: "(max-height: 400px)",
    });

    const TwoZeroZeroPercent = useMediaQuery({
        query: "(max-height: 500px)",
    });

    const OneSevenFivePercent = useMediaQuery({
        query: "(max-height: 600px)",
    });

    const OneFivePercent = useMediaQuery({
        query: "(max-height: 700px)",
    });

    const OneTwoFivePercent = useMediaQuery({
        query: "(max-height: 800px)",
    });

    const OneOneTenPercent = useMediaQuery({
        query: "(max-height: 900px)",
    });

    // const [showVideo, setShowVideo] = useState(true);
    const [showVideo, setShowVideo] = useState(true);
    const [currentVideo, setCurrentVideo] = useState(v1);

    const { t } = useTranslation();
    const { view, setView } = useContext(ViewContext);
    const [activeButtonIndex, setActiveButtonIndex] = useState(0);
    const [emailSent, setEmailSent] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();

    const closeModal = () => {
        setIsOpen(false);
    };

    const openModal = () => {
        setIsOpen(true);
    };
    const CountUpConstants = [
        { amount: 10363746, name: "hero-total-regs" },
        {
            amount: 5426683,
            name: "total-authentications",
        },
        {
            amount: 33,
            name: "integrated-agencies",
        },
    ];
    return (
        <>
            <div className='z-50'>
                <div className='relative  pt-20 md:pt-32 lg:pt-20 flex md:items-end md:pb-[4%] h-fit min-h-screen bg-cover bg-top'>
                    <div className='absolute top-0 left-0 w-full min-h-dvh z-20 overflow-hidden'>
                        <Carousel />
                    </div>

                    <div className='absolute top-0 left-0 w-full h-full min-h-screen bg-hero-gradient z-20'></div>

                    <div className='z-30 flex flex-col justify-end w-full gap-5 text-center text-white md:justify-center md:gap-0'>
                        <div className=' flex flex-col items-center justify-center w-full pb-10 text-center align-bottom md:relative md:pb-0 md:justify-end md:items-start md:px-10 bottom-10'>
                            {/* FAYDA FOR ETHIOPIA TEXT SECTION */}
                            <div
                                className={`${
                                    one
                                        ? " bg-lightblu flex tracking-tighter md:tracking-normal"
                                        : TwoFiveZeroPercent
                                        ? "bg-darkorang flex tracking-tighter md:tracking-normal text-4xl md:text-[1.8em] md:leading-[1] "
                                        : TwoZeroZeroPercent
                                        ? " bg-darkre flex tracking-tighter md:tracking-normal text-4xl md:text-[2.3em] md:leading-[1] "
                                        : OneSevenFivePercent
                                        ? " bg-blac flex tracking-tighter md:tracking-normal text-4xl md:text-[2.7em] md:leading-[1] "
                                        : OneFivePercent
                                        ? "   bg-darkgree flex tracking-tighter md:tracking-normal text-4xl md:text-5xl "
                                        : OneTwoFivePercent
                                        ? "  bg-gre flex tracking-tighter md:tracking-normal text-4xl md:text-6xl "
                                        : OneOneTenPercent
                                        ? "bg-viole flex tracking-tighter md:tracking-normal text-4xl md:text-7xl "
                                        : "bg-tahiti-40 flex tracking-tighter md:tracking-normal  text-4xl  md:text-8xl "
                                }


          
            }`}
                            >
                                <h1 className='pr-2.5 text-green font-bold'>
                                    {t("hero-fayda")}
                                </h1>

                                <h1 className=''>{t("hero-for-ethiopia")}</h1>
                            </div>

                            {/* Hero Text Description Section */}
                            <div class='justify-center w-full md:text-lg md:leading-relaxed text-sm md:w-[50%] lg:w-[34%]'>
                                <p className='px-12 pt-3 pb-2 md:text-left md:px-0'>
                                    {t("hero-text")}
                                </p>
                                <p className='px-12 pt-3 pb-2 md:text-left md:px-0'>
                                    {t("hero-text2")}
                                </p>
                            </div>

                            {/* Register Button  */}
                            <div className='flex flex-col lg:flex-row gap-5'>
                                <button
                                    onClick={openModal}
                                    className='w-full lg:w-[250px] min-w-fit md:mt-1 md:mb-4 mt-3 md:text-xl text-xl px-14 md:px-14 font-semibold text-white/85 shadow-md scale-90 h-10 md:h-10 border-2 border-green rounded-3xl md:scale-100 hover:bg-[#083247]  hover:scale-105 transition-all transform duration-300'
                                >
                                    {t("hero-register")}
                                </button>

                                <Link
                                    to='/cardprint'
                                    className='w-full lg:w-[250px] min-w-fit md:mt-1 md:mb-4 mt-3 md:text-xl text-xl px-14 md:px-14 font-semibold text-white/85 shadow-md scale-90 h-10 md:h-10 border-2 border-green rounded-3xl md:scale-100 hover:bg-[#083247]  hover:scale-105 transition-all transform duration-300'
                                >
                                    <div className='h-full w-full flex items-center text-center'>
                                        <span className='w-full'>
                                            {t("hero-cardPrint")}
                                        </span>
                                    </div>
                                </Link>

                                <Link
                                    to='/help'
                                    className='w-full lg:w-[250px] min-w-fit mx-auto md:mt-1 md:mb-4 mt-3 md:text-xl text-xl px-14 md:px-14 font-semibold text-white/85 shadow-md scale-90 h-10 md:h-10 border-2 border-green rounded-3xl md:scale-100 hover:bg-[#083247]  hover:scale-105 transition-all transform duration-300'
                                >
                                    <div className='h-full w-full flex items-center text-center'>
                                        <span className='w-full'>
                                            {t("hero-support")}
                                        </span>
                                    </div>
                                </Link>
                            </div>
                        </div>

                        <div className='flex items-start w-full justify-center md:justify-start text-center align-bottom  bottom-16 b-lightblue md:pb-0 px-10'>
                            {/*Whole Counter Section */}
                            <div className='grid w-full gap-5 md:gap-2 md:flex md:justify-center lg:min-w-[40%] max-w-fit items-center mb-5 text-center md:items-start bottom-10 rounded-xl border border-white/70 bg-white/5 py-5 md:py-0 px-10 place-items-center'>
                                {CountUpConstants.map((countItem, index) => (
                                    <div
                                        className={`md:py-3 w-full md:place-self-center ${
                                            index === 0
                                                ? "md:pr-5"
                                                : index === 1
                                                ? "md:border-r-2 md:border-l-2 md:px-5"
                                                : index === 2
                                                ? "md:pl-5"
                                                : ""
                                        }`}
                                    >
                                        {
                                            <div className='text-center text-xl md:space-y-5 md:text-4xl font-bold'>
                                                <div className='text-2xl md:text-5xl'>
                                                    <CountUp
                                                        isCounting
                                                        thousandsSeparator=','
                                                        start={0}
                                                        end={countItem.amount}
                                                        duration={3}
                                                    />
                                                </div>
                                                <p className='text-green text-lg md:text-[1.67rem]'>
                                                    {t(countItem.name)}
                                                </p>
                                            </div>
                                        }
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div
                            className={`absolute ${
                                one
                                    ? "bottom-0 justify-start hidden w-full text-xs  md:flex"
                                    : TwoFiveZeroPercent
                                    ? "bottom-0 justify-start hidden w-full text-xs  md:flex "
                                    : TwoZeroZeroPercent
                                    ? "bottom-0 justify-start hidden w-full text-xs  md:flex"
                                    : OneSevenFivePercent
                                    ? "bottom-0 justify-start hidden w-full text-xs  md:flex"
                                    : OneFivePercent
                                    ? "bottom-0 justify-start hidden w-full text-xs  md:flex"
                                    : OneTwoFivePercent
                                    ? "bottom-0 justify-start hidden w-full text-xs  md:flex"
                                    : OneOneTenPercent
                                    ? "bottom-0 justify-start hidden w-full text-xs  md:flex"
                                    : "bottom-0 justify-start hidden w-full text-xs  md:flex"
                            }
          
            }`}
                        >
                            <button
                                className={`${
                                    view == "Citizen"
                                        ? " bg-white text-black "
                                        : " bg-button-gradient text-white text-md shadow-2xl md:rounded-sm "
                                } 
                
                
                content-center p-2 px-5 md:px-8 md:w-fit w-[50%]   hover:cursor-pointer  `}
                                onClick={() => {
                                    setActiveButtonIndex(0);
                                    setView("Citizen");
                                }}
                            >
                                {t("residents")}
                            </button>

                            <button
                                className={`${
                                    view == "Stakeholder"
                                        ? "bg-white text-black md:rounded-tr-md"
                                        : " md:rounded-tr-md bg-button-gradient text-white shadow-2xl md:rounded-sm "
                                } md:w-fit w-[50%] content-center p-2 px-10  hover:cursor-pointer`}
                                onClick={() => {
                                    setActiveButtonIndex(1);
                                    setView("Stakeholder");
                                }}
                            >
                                {t("stakeholder")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {isOpen && (
                <div
                    className='fixed inset-0 flex items-center justify-center backdrop-blur-[2px] bg-black/75 bg-opacity-50 z-50 transition-all duration-500 '
                    onClick={closeModal}
                >
                    <div
                        className='bg-white rounded-lg p-4 w-full max-w-2xl lg:p-8 mx-3 flex flex-col justify-between' // Added flex flex-col justify-between here
                        onClick={(e) => e.stopPropagation()}
                    >
                        {/* Content */}
                        <div className='flex flex-col lg:flex-row w-full h-full text-2xl text-white'>
                            <div className='flex flex-col w-full lg:w-1/2 h-full items-center px-2 lg:px-5 py-4 flex-grow'>
                                <div className='text-MetallicBlue text-lg lg:text-xl text-center mb-4 lg:mb-10'>
                                    <Trans
                                        i18nKey='landing-locations'
                                        components={{
                                            Link: (
                                                <a href='https://id.et/proof'>
                                                    id.et/proof
                                                </a>
                                            ),
                                        }}
                                    />
                                </div>
                            </div>

                            {/* <div className='hidden lg:block border h-40 border-MetallicBlue mx-4'></div> */}
                            {/* <div className='flex flex-col w-full lg:w-1/2 h-full items-center px-2 lg:px-5 py-4 flex-grow'>
                                {" "}
                              
                                <div className='text-MetallicBlue text-lg lg:text-xl text-center mb-4 lg:mb-10'>
                                    {t("landing-preregistration")}
                                </div>
                            </div> */}
                        </div>

                        {/* Buttons */}
                        <div className='flex flex-col lg:flex-row w-full h-full text-2xl text-white mt-4'>
                            {" "}
                            <div className='flex flex-col w-full lg:w-1/2 h-full items-center justify-center  lg:ml-36 px-2 lg:px-5 py-4'>
                                <a href='/locations'>
                                    <button className='bg-MetallicBlue px-4 lg:px-8 py-2 rounded-lg hover:bg-green hover:text-MetallicBlue'>
                                        {t("landing-locations-button")}
                                    </button>
                                </a>
                            </div>
                            {/* <a
                                href='https://register.fayda.et'
                                target='_blank'
                                rel='noreferrer'
                                className='flex flex-col w-full lg:w-1/2 h-full items-center px-2 lg:px-5 py-4'
                            >
                                <div>
                                    <div className='bg-MetallicBlue px-4 lg:px-8 py-2 rounded-lg hover:bg-green hover:text-MetallicBlue'>
                                        {t("landing-preregistration-button")}
                                    </div>
                                </div>
                            </a> */}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Hero;
