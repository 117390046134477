import React from "react";
import { IoWarning } from "react-icons/io5";

import ethiopost from "../../../../assets/cardprint/Vector Smart Object-01 (2).png";
import ethiotele from "../../../../assets/cardprint/Vector Smart Object-2-01 (2).png";
import card from "../../../../assets/cardprint/credit-cgdrgard.png";
import i18next, { t } from "i18next";

const Body = () => {
    return (
        <div className='w-full h-full gap-8 px-5 pb-10 md:px-16 lg:px-32 py-20'>
            <div className='grid place-items-center xl:grid-cols-7 gap-14 md:gap-24 w-full lg:px-16 text-MetallicBlue'>
                <div className='flex flex-col gap-3 xl:col-span-3 rounded-lg min-h-[300px] h-full w-full text-white'>
                    <div className='bg-id-services-hero bg-right bg-cover rounded-lg shadow-md p-5 relative overflow-clip border-[#f1f1f1] border-[8px]'>
                        <div className='bg-hero-gradient-toBottom-metallic absolute top-0 w-full h-full left-0 right-0 z-20'></div>

                        <div className='relative z-30 text-white'>
                            <p className='text-2xl font-semibold mb-4'>
                                {t("card-print-body-description-1")}
                            </p>
                            <div className='space-y-1'>
                                {i18next.language !== "eng" ? null : (
                                    <p>
                                        <span className='text-lg font-bold pr-1'>
                                            {t("card-print-flexibility")}
                                        </span>{" "}
                                        {t("flexibility-description")}
                                    </p>
                                )}
                                <p>
                                    <span className='text-lg font-bold pr-1'>
                                        {t("card-print-quality")}
                                    </span>
                                    {t("quality-description")}
                                </p>
                                <p>
                                    <span className='text-lg font-bold pr-1'>
                                        {t("card-print-secure")}
                                    </span>
                                    {t("secure-description")}
                                </p>
                            </div>
                            <div>
                                <p className='font-bold text-2xl mt-6 mb-2'>
                                    {t("how-to-proceed")}
                                </p>
                                <ul className='list-disc px-3'>
                                    <li>{t("step-1")}</li>
                                    <li>{t("step-2")}</li>
                                    <li>{t("step-3")}</li>{" "}
                                    <li>{t("step-4")}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='bg-darkorange rounded-md relative shadow-lg p-5 border-[#f1f1f1] border-[8px]'>
                        <p className='flex items-center gap-2 font-bold text-3xl  text-MetallicBlue'>
                            <IoWarning />
                            {t("disclaimer")}
                        </p>
                        {t("disclaimer-description")}
                    </div>
                </div>
                <div className='text-MetallicBlue order-first xl:order-last px-5 md:px-10 text-center pb-10 w-full rounded-lg shadow-sm min-h-[300px] h-full lg:col-span-4'>
                    <div className='mb-5'>
                        <p className='text-3xl font-bold'>
                            {t("printing-companies")}
                        </p>
                        <p className='text-xl'>
                            {t("printing-companies-description")}
                        </p>
                    </div>

                    <div className='w-full flex justify-between gap-5 md:gap-10'>
                        <div className='w-full max-w-[320px] flex flex-col gap-3 h-fit'>
                            <div className='bg-ethiopost-gradient flex flex-col gap-2 pt-3 pb-5 shadow-lg aspect-video w-full px-5 rounded-lg'>
                                <img
                                    src={card}
                                    alt='ethiopost'
                                    className='object-contain w-full'
                                />
                                <img
                                    src={ethiopost}
                                    alt='ethiopost'
                                    className='object-contain w-full'
                                />
                                <a
                                    href='https://card-order.fayda.et/'
                                    className='mx-auto mt-5'
                                >
                                    <p className='px-4 md:px-8 py-1 md:py-1.5 bg-MetallicBlue text-sm md:text-base text-white rounded-full w-fit text-center'>
                                        {t("order-now")}
                                    </p>
                                </a>
                            </div>

                            <div>
                                <p className='text-xl font-bold'>
                                    {t("ethio-post-card-service")}
                                </p>
                                <p>{t("ethiopost-card-description")}</p>
                            </div>
                        </div>
                        <div className='w-full max-w-[320px] flex flex-col gap-3 h-fit'>
                            <div className='flex bg-ethiotele-gradient flex-col gap-2 pt-3 pb-5 shadow-lg aspect-video w-full px-5 rounded-lg'>
                                <img
                                    src={card}
                                    alt='ethiopost'
                                    className='object-contain w-full'
                                />
                                <img
                                    src={ethiotele}
                                    alt='ethiotelecom'
                                    className='object-contain w-full'
                                />
                                <a
                                    href='https://teleprint.fayda.et/home'
                                    className='mx-auto mt-5'
                                >
                                    <p className='px-4 md:px-8 py-1 md:py-1.5 bg-MetallicBlue text-sm md:text-base text-white rounded-full w-fit text-center'>
                                        {t("order-now")}
                                    </p>
                                </a>
                            </div>
                            <div>
                                <p className='text-xl font-bold'>
                                    {t("ethio-tele-card-service")}
                                </p>
                                <p>{t("ethiotele-card-description")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Body;
