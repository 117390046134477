import React from "react";
import { useTranslation } from "react-i18next";
const Hero = () => {
    const { t } = useTranslation();

    return (
        <>
            <div class='bg-card-print-hero bg-cover h-full w-full'>
                <div class='bg-top-bot-gradient w-full'>
                    <div className='w-full h-full gap-8 px-5 pb-10 md:px-16'>
                        <div className='flex flex-col gap-10 mx-5 md:mx-32 md:pt-[12%] pt-[30%] text-4xl md:text-6xl'>
                            <h1 className='pr-2 mb-0 font-bold text-white '>
                                {t("card-print-title")}
                                <span className='font-normal text-green'>
                                    {t("card-print-title2")}
                                </span>
                            </h1>
                            <div className='w-full h-fit gap-1 pb-10'>
                                <p className='text-justify text-white text-xl lg:text-2xl'>
                                    {t("card-print-title-body")}{" "}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Hero;
