import React, { useState, useEffect } from "react";

import HeroLandingPage from "../idServices/components/hero/heroLandingPage";
import FooterService from "../../components/footer/footer";
import Idandmobile from "./components/cardprint/cardprint";
import Getfayda from "./components/getfayda/getfayda";
import Updatenumber from "./components/updatenumber/updatenumber";
import Faydaapp from "./components/faydaapp/faydaapp";
import GetHelp from "./components/help/help";

function IdServices() {
    const [citizen, setCitizen] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <HeroLandingPage citizen={citizen} />

            <Getfayda id={"get-no"} />
            <Idandmobile />
            <GetHelp />
            <Updatenumber id={"update-no"} />
            <Faydaapp />
            <FooterService />
        </>
    );
}

export default IdServices;
