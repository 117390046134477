import React from "react";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import MobileImg from "../../../../assets/fayda-app.png";
import { ReactComponent as AppleLogo } from "../../../../assets/applelogo.svg";
import { ReactComponent as Googleplaylogo } from "../../../../assets/googleplaylogo.svg";

import { useNavigate } from "react-router-dom";

const Faydaapp = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });

    return (
        <>
            {isDesktopOrLaptop ? (
                <div class=' text-white  bg-update-services bg-left-top bg-cover h-fit w-full'>
                    <div className='flex flex-col pb-0 bg-black/40'>
                        <div
                            id='mobile-app'
                            className='z-0 flex flex-col bg-white'
                        >
                            <div className='flex items-end gap-20 px-28 text-[#083247]'>
                                <div
                                    id='mobile-app'
                                    className='flex flex-col mb-40  w-[55%]'
                                >
                                    <h1 className='text-6xl font-semibold text-MetallicBlue'>
                                        {t("services-mobile-app")}
                                    </h1>
                                    <p className='text-2xl font-normal w-[80%] text-justify mt-5 '>
                                        {t("service-mobile-app-text")}{" "}
                                    </p>

                                    <div className='flex items-center grid-cols-3 gap-5 mt-8 '>
                                        {/* <div className='w-[40%] shadow-sm gap-2 shadow-white/80 h-full justify-center flex py-1 align-middle bg-transparent border-2 border-white rounded-lg  place-items-center  hover:bg-[#083247] transition-all  duration-300 hover:scale-105'>
                                            <div className=''>
                                                <AppleLogo className='h-16 ' />
                                            </div>

                                            <div className='flex-col items-center justify-center leading-tight rounded-lg cursor-pointer   '>
                                                <h1 className='lg:text-lg md:text-lg font-extralight text-white/50'>
                                                    Download on the
                                                </h1>
                                                <h1 className='font-bold lg:text-2xl md:text-xl sm:text-lg '>
                                                    App Store
                                                </h1>
                                            </div>
                                        </div>

                                        <div className='w-[40%] shadow-sm gap-2 shadow-white/80 h-full justify-center flex py-1 align-middle bg-transparent border-2 border-white rounded-lg  place-items-center  hover:bg-green transition-all  duration-300 hover:scale-105'>
                                            <div className='flex '>
                                                <Googleplaylogo className='h-16' />
                                            </div>

                                            <div className='flex flex-col items-center justify-center leading-tight rounded-lg cursor-pointer  '>
                                                <h1 className='lg:text-lg md:text-lg font-extralight text-white/50'>
                                                    Get it on
                                                </h1>
                                                <h1 className='font-bold lg:text-2xl md:text-xl sm:text-lg '>
                                                    Google Play
                                                </h1>
                                            </div>
                                        </div> */}
                                        <div className='w-fit  shadow-sm gap-2 shadow-white/80 h-full justify-center flex py-1 align-middle bg-transparent border-2 border-white rounded-lg  place-items-center'>
                                            <div className='flex-col items-center justify-center leading-tight rounded-lg cursor-pointer  shadow-sm border-2 border-MetallicBlue py-2.5 px-12'>
                                                <p className='text-2xl '>
                                                    {t("coming-soon")}{" "}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='relative w-[50%] lg:-left-10 pb-[37px]'>
                                    <img
                                        src={MobileImg}
                                        alt='Logo'
                                        className='items-start align-top scale-y-110'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div
                    id='mobile-app'
                    className='z-0 flex flex-col w-full px-10 bg-dark py-5'
                >
                    <div className='flex pb-5 '>
                        <div
                            id='mobile-app'
                            className='flex flex-col w-full gap-5'
                        >
                            <div className='flex flex-col items-center gap-5'>
                                <h1 className='text-4xl font-semibold text-MetallicBlue'>
                                    {t("services-mobile-app")}
                                </h1>
                                <p className='text-lg font-normal text-justify  '>
                                    {t("service-mobile-app-text")}{" "}
                                </p>
                            </div>

                            <div className='flex items-center justify-center gap-2 pt-4 pb-4 '>
                                {/* <div className='shadow-sm gap-1 w-full shadow-white/80  justify-center flex py-0.5 align-middle bg-transparent border-2 border-white rounded-lg  place-items-center  hover:bg-[#083247] transition-all  duration-300 hover:scale-105'>
                                    <div className=''>
                                        <AppleLogo className='h-9 ' />
                                    </div>

                                    <div className='flex flex-col items-center justify-center leading-tight rounded-lg cursor-pointer '>
                                        <h1 className='text-xs font-extralight text-white/90'>
                                            Download on the
                                        </h1>
                                        <h1 className='text-xl font-bold '>
                                            App Store
                                        </h1>
                                    </div>
                                </div>

                                <div className='  shadow-sm gap-1 w-full shadow-white/80  justify-center flex py-0.5 align-middle bg-transparent border-2 border-white rounded-lg place-items-center  hover:bg-[#083247] transition-all  duration-300 hover:scale-105'>
                                    <div className='flex '>
                                        <Googleplaylogo className='h-8' />
                                    </div>

                                    <div className='flex flex-col items-center justify-center leading-tight rounded-lg cursor-pointer  '>
                                        <h1 className='text-xs font-extralight text-white/80'>
                                            Get it on
                                        </h1>
                                        <h1 className='text-xl font-bold '>
                                            Google Play
                                        </h1>
                                    </div>
                                </div> */}
                                <div className='w-fit  shadow-sm gap-2 shadow-white/80 h-full justify-center flex py-1 align-middle bg-transparent border-2 border-white rounded-lg  place-items-center'>
                                    <div className='flex-col items-center justify-center leading-tight rounded-lg cursor-pointer  shadow-sm border-2 border-MetallicBlue py-2.5 px-12'>
                                        <p className='text-2xl '>
                                            {t("coming-soon")}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Faydaapp;
