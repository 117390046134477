import { Route, Routes, Navigate } from "react-router-dom";

import { Helmet } from "react-helmet";
import Landing from "./views/landing/landing";
import AboutPage from "./views/about/about";
import IdServices from "./views/idServices/idServices";
import ContactUs from "./views/contactus/contactus";
import Media from "./views/media/media";
import Mediacopy from "./views/media copy/media";
import Resources from "./views/resources/resources";
import Career from "./views/career";
import Strategies from "./views/strategies/strategies";
import Gallery from "./views/gallery/gallery";
import Dataset from "./views/dataset/dataset";
import SingleNews from "./views/news/singleNews";
import Proof from "./views/proof/proof";
import Enrollment from "./views/enrollment/enrollment";

import FooterPolicies from "./views/footer pages/footerPolicies";
import FooterCopyright from "./views/footer pages/footerCopyright";
import FooterTerms from "./views/footer pages/footerTerms";
import MyPdf from "./views/wp-pdfiles/myPdf";
import Page404 from "./views/404/404";
import Locations from "./views/locations";
import { matchPath } from "react-router";
import Faqpage from "./views/faq page/singleFaq";
import Benefits from "./views/benefits/benefits";
import Contact from "./views/Contact/contact";
import Principles from "./views/principles/principles";
// import GrievanceForm from "./views/grievance/grievance";
//import Help from "./views/grievance/help";
import UserId from "./views/userId/userId";
import GetId from "./views/getId/getId";
import Qrcode from "./views/QRCode/qrcode";
import Credentials from "./views/credentials/credentials";
import PrintRequest from "./views/printRequest";
import Podcast from "./views/podcast/components/podcast";
import APIRequest from "./views/apiRequest";
import CardPrice from "./views/cardPrice";
import BlogContainer from "./views/blog/BlogContainer";
import Worldbank from "./views/worldbank/Worldbank";
import CardPrint from "./views/cardPrint/CardPrint";
import Partners from "./views/partners/partners";
import Help from "./views/help";
import DownloadLogos from "./helpers/download";
import CardNew from "./views/cardNew/cardNew";
import Temp from "./views/temp/Temp";
import Authentication from "./views/authentication/authentication";
import EthioPostLocations from "./views/Ethiopostlocations/EthioPostLocations";
import PartyRegistrationForm from "./views/relyingPartyRegistrationForm/partyRegistrationForm";
import ResendHelp from "./views/resendHelp/resendHelp";
import ApiIntegration from "./views/api-documentation";
import CardPrintInfo from "./views/CardPrintInfo/CardPrintInfo";
//import CountDown from "./components/countdown/countDown";

function RedirectToFaqWithQuery({ id }) {
  const faqNumber = id.substr(1); // remove the leading "+" character

  return <Navigate to={`/faq?faq=${faqNumber}`} replace />;
}
function RedirectToCardPrint() {
  window.location.replace('https://card-order.fayda.et/')

  // useEffect(() => {
  //   navigate('https://card-order.fayda.et/', { replace: true });
  // }, [navigate]);

  // return null;
}

function RedirectResidentPortal() {
  window.location.replace('https://resident.fayda.et/')

  // useEffect(() => {
  //   navigate('https://card-order.fayda.et/', { replace: true });
  // }, [navigate]);

  // return null;
}

function RedirectEthioTelePrint() {
  window.location.replace('https://teleprint.fayda.et')

  // useEffect(() => {
  //   navigate('https://card-order.fayda.et/', { replace: true });
  // }, [navigate]);

}
const RoutesComponent = () => {
  const faqRoutes = [];

  for (let i = 1; i <= 17; i++) {
    const path = `/faq${i}`;
    const route = (
      <Route
        key={i}
        path={path}
        element={
          <>
            <Helmet>
              <title>National ID | Faq</title>
            </Helmet>
            <Faqpage />
          </>
        }
      />
    );
    faqRoutes.push(route);
  }
  // credentials
  return (
    <Routes>
      {/* credentials */}

      <Route
        path="/ethiopost-locations"
        element={
          <>
            <Helmet>
              <title>National ID | Ethiopost Locations</title>
            </Helmet>
            <EthioPostLocations />
          </>
        }
      />
      <Route
        path="/credentials"
        element={
          <>
            <Helmet>
              <title>National ID | Credentials</title>
            </Helmet>
            <Credentials />
          </>
        }
      />
      <Route
        path="/authentication"
        element={
          <>
            <Helmet>
              <title>National ID | Authentication</title>
            </Helmet>
            <Authentication />
          </>
        }
      />
      <Route
        path="/"
        element={
          <>
            <Helmet>
              <title>National ID | Home</title>
            </Helmet>
            <Landing />
          </>
        }
      />

      {/* <Route
        path="/cardNew"
        element={
          <>
            <Helmet>
              <title>National ID | Webinar</title>
            </Helmet>
            <CardNew />
          </>
        }
      /> */}
      <Route
        path="/about"
        element={
          <>
            <Helmet>
              <title>National ID | About Us</title>
            </Helmet>
            <AboutPage />
          </>
        }
      />
      <Route
        path="/api"
        element={
          <>
            <Helmet>
              <title>National ID | Api Integration</title>
            </Helmet>
            <ApiIntegration />
          </>
        }
      />

      <Route
        path="/worldbank"
        element={
          <>
            <Helmet>
              <title>National ID | World Bank</title>
            </Helmet>
            <Worldbank />
          </>
        }
      />
      <Route
        path="/services"
        element={
          <>
            <Helmet>
              <title>National ID | Services</title>
            </Helmet>
            <IdServices />
          </>
        }
      />

      <Route
        path="/faq"
        element={
          <>
            <Helmet>
              <title>National ID | FAQ</title>
            </Helmet>
            <ContactUs />
          </>
        }
      />

      <Route
        path="/news"
        element={
          <>
            <Helmet>
              <title>National ID | News</title>
            </Helmet>
            <Media />
          </>
        }
      />
      <Route
        path="/relying-party"
        element={
          <>
            <Helmet>
              <title>National ID | Relying Party</title>
            </Helmet>
            <PartyRegistrationForm />
          </>
        }
      />
      <Route
        path="/press"
        element={
          <>
            <Helmet>
              <title>National ID | Press</title>
            </Helmet>
            <Media />
          </>
        }
      />

      <Route
        path="/tender"
        element={
          <>
            <Helmet>
              <title>National ID | Tender</title>
            </Helmet>
            <Media />
          </>
        }
      />

      <Route
        path="/webinar"
        element={
          <>
            <Helmet>
              <title>National ID | Webinar</title>
            </Helmet>
            <Media />
          </>
        }
      />

      <Route
        path="/events"
        element={
          <>
            <Helmet>
              <title>National ID | Events</title>
            </Helmet>
            <Mediacopy />
          </>
        }
      />

      <Route
        path="/proof"
        element={
          <>
            <Helmet>
              <title>National ID | Proof of Identity</title>
            </Helmet>
            <Proof />
          </>
        }
      />

      <Route
        path="/resources"
        element={
          <>
            <Helmet>
              <title>National ID | Resources</title>
            </Helmet>
            <Resources title={"All"} />
          </>
        }
      />

      <Route
        path="/media"
        element={
          <>
            <Helmet>
              <title>National ID | Media Gallery</title>
            </Helmet>
            <Gallery title="Pictures" />
          </>
        }
      />
      <Route
        path="/videos"
        element={
          <>
            <Helmet>
              <title>National ID | Media Gallery</title>
            </Helmet>
            <Gallery title={"Videos"} />
          </>
        }
      />

      <Route
        path="/enrollment"
        element={
          <>
            <Helmet>
              <title>National ID | Enrollment</title>
            </Helmet>
            <Enrollment />
          </>
        }
      />

      <Route
        path="/strategies"
        element={
          <>
            <Helmet>
              <title>National ID | Strategies</title>
            </Helmet>
            <Strategies />
          </>
        }
      />

      <Route
        path="/mou"
        element={
          <>
            <Helmet>
              <title>National ID | MoU</title>
            </Helmet>
            <Resources title={"MoU"} />
          </>
        }
      />

      <Route
        path="/decks"
        element={
          <>
            <Helmet>
              <title>National ID | Decks</title>
            </Helmet>
            <Resources title={"Decks"} />
          </>
        }
      />

      <Route
        path="/policies"
        element={
          <>
            <Helmet>
              <title>National ID | Policies and Strategies</title>
            </Helmet>
            <Resources title={"Policies and Strategies"} />
          </>
        }
      />

      <Route
        path="/documents"
        element={
          <>
            <Helmet>
              <title>National ID | Publications</title>
            </Helmet>
            <Resources title={"Publications"} />
          </>
        }
      />

      <Route
        path="/nidspecifications"
        element={
          <>
            <Helmet>
              <title>National ID | Specifications</title>
            </Helmet>
            <Resources title={"Specifications"} />
          </>
        }
      />
      <Route
        path="/procurement"
        element={
          <>
            <Helmet>
              <title>National ID | Procurement</title>
            </Helmet>
            <Resources title={"Procurement"} />
          </>
        }
      />
      <Route
        path="/law"
        element={
          <>
            <Helmet>
              <title>National ID | Law</title>
            </Helmet>
            <Resources title={"Law"} />
          </>
        }
      />
      <Route
        path="/manuals"
        element={
          <>
            <Helmet>
              <title>National ID | Manuals</title>
            </Helmet>
            <Resources title={"Manuals"} />
          </>
        }
      />

      <Route
        path="/career"
        element={
          <>
            <Helmet>
              <title>National ID | Career</title>
            </Helmet>
            <Career title={"Career"} />
          </>
        }
      />

      <Route
        path="/dataset"
        element={
          <>
            <Helmet>
              <title>National ID | Dataset</title>
            </Helmet>
            <Dataset title={"Dataset"} />
          </>
        }
      />

      <Route
        path="/updates/:type/:title"
        element={
          <>
            {/* <Helmet>
              <title>National ID | Single News</title>
             <meta name="National ID" content="National Id Official Website" />
            
            </Helmet> */}
            <SingleNews />
          </>
        }
      />

      <Route
        path="/websitepolicies"
        element={
          <>
            <Helmet>
              <title>National ID | Website Policies</title>
            </Helmet>
            <FooterPolicies />
          </>
        }
      />

      <Route
        path="/websitecopyright"
        element={
          <>
            <Helmet>
              <title>National ID | Website Copyright</title>
            </Helmet>
            <FooterCopyright />
          </>
        }
      />

      <Route
        path="/websiteterms"
        element={
          <>
            <Helmet>
              <title>National ID | Website Terms</title>
            </Helmet>
            <FooterTerms />
          </>
        }
      />

      <Route
        path="/wp-content/uploads/2023/03/Biometrics-registration-kit-specification-March.pdf"
        element={
          <>
            <MyPdf />
          </>
        }
      />
      <Route
        path="/wp-content/uploads/2023/02/Biometric-Device-Specifications-V2.0.docx.pdf"
        element={
          <>
            <MyPdf />
          </>
        }
      />

      <Route
        path="/pilot"
        element={
          <>
            <Helmet>
              <title>National ID | Pilot</title>
            </Helmet>
            <AboutPage />
          </>
        }
      />

      <Route
        path="/partners"
        element={
          <>
            <Helmet>
              <title>National ID | Partners</title>
            </Helmet>
            <Partners />

            {/* <AboutPage /> */}
          </>
        }
      />
      {/* <Route
        path="/partnerss"
        element={
          <>
            <Helmet>
              <title>National ID | Partners</title>
            </Helmet>
          </>
        }
      /> */}
      {/* <Route
        path="/cardprint"
        element={
          <>
            <Helmet>
              <title>National ID | Card Print</title>
            </Helmet>
            <CardPrint />
          </>
        }
      /> */}

      <Route
        path="/history"
        element={
          <>
            <Helmet>
              <title>National ID | History</title>
            </Helmet>
            <AboutPage />
          </>
        }
      />

      {/* <Route
        path="/authentication"
        element={
          <>
            <Helmet>
              <title>National ID | Authentication</title>
            </Helmet>
            <AboutPage />
          </>
        }
      /> */}

      {/* <Route
        path="/strategy"
        element={
          <>
            <Helmet>
              <title>National ID | Strategy</title>
              <meta name="description" content="Helmet application" />
  </Helmet>
            <AboutPage />
          </>
        }
      /> */}

      <Route
        path="/faydanumber"
        element={
          <>
            <Helmet>
              <title>National ID | Fayda Number</title>
            </Helmet>
            <IdServices />
          </>
        }
      />

      <Route
        path="/app"
        element={
          <>
            <Helmet>
              <title>National ID | App</title>
            </Helmet>
            <IdServices />
          </>
        }
      />

      <Route
        path="/faq"
        element={
          <>
            <Helmet>
              <title>National ID | FAQ</title>
            </Helmet>
            <ContactUs />
          </>
        }
      />
      <Route
        path="/faq:id"
        element={<RedirectToFaqWithQuery id={matchPath} />}
      />

      <Route
        path="/en/faq16"
        element={
          <>
            <Helmet>
              <title>National ID | FAQ</title>
            </Helmet>
            <ContactUs />
          </>
        }
      />
      <Route
        path="/faq2amharic"
        element={
          <>
            <Helmet>
              <title>National ID | FAQ</title>
            </Helmet>
            <ContactUs />
          </>
        }
      />

      <Route
        path="/contact"
        element={
          <>
            <Helmet>
              <title>National ID | Contact</title>
            </Helmet>
            <Contact />
          </>
        }
      />
      <Route
        path="/locations"
        element={
          <>
            <Helmet>
              <title>National ID | Locations</title>
            </Helmet>
            <Locations />
          </>
        }
      />
      <Route
        path="/principles"
        element={
          <>
            <Helmet>
              <title>National ID | Principles</title>
            </Helmet>
            <Principles />
          </>
        }
      />
      <Route
        path="/help"
        element={
          <>
            <Helmet>
              <title>National ID | Help</title>
            </Helmet>
            <ResendHelp />
          </>
        }
      />

      <Route
        path="/call"
        element={
          <>
            <Helmet>
              <title>National ID | Help</title>
            </Helmet>
            <ResendHelp />
          </>
        }
      />
      {/* <Route
        path="/6R04mVB5sd2bJxSmhI6Cg76eJUb2mgw5Jk0T4zL8H29TYo8mXU*Yn"
        element={
          <>
            <ResendHelp/>
          </>
        }
      /> */}
      <Route path="/logos" element={<DownloadLogos />} />

      {/* <Route
        path="/grievance"
        element={
          <>
            <Helmet>
              <title>National ID | Help</title>
              <meta name="description" content="Helmet application" />
  </Helmet>
            <GrievanceForm/>
          </>
        }
      /> */}

      <Route
        path="*"
        element={
          <>
            <Helmet>
              <title>National ID | 404 Not Found</title>
            </Helmet>
            <Page404 />
          </>
        }
      />
      <Route
        path="/userId"
        element={
          <>
            <Helmet>
              <title>National ID | 404 Not Found</title>
            </Helmet>
            <UserId />
          </>
        }
      />

      <Route
        path="/benefits"
        element={
          <>
            <Helmet>
              <title>National ID | Benefits</title>
            </Helmet>
            <Benefits />
          </>
        }
      />
      <Route
        path="/getId"
        element={
          <>
            <Helmet>
              <title>National ID | Get ID</title>
            </Helmet>
            <GetId />
          </>
        }
      />
      <Route
        path="/scanId"
        element={
          <>
            <Helmet>
              <title>National ID | Scan ID</title>
            </Helmet>
            <Qrcode />
          </>
        }
      />
      {/* <Route
        path="/cardprint"
        element={
          <>
            <Helmet>
              <title>National ID | Print</title>
            </Helmet>
            <PrintRequest />
          </>
        }
      /> */}

      <Route
        path="/podcast"
        element={
          <>
            <Helmet>
              <title>National ID | Podcast</title>
            </Helmet>
            <Podcast />
          </>
        }
      />

      <Route
        path="/podcast"
        element={
          <>
            <Helmet>
              <title>National ID | Podcast</title>
            </Helmet>
            <Podcast />
          </>
        }
      />
      <Route
        path="/apirequest"
        element={
          <>
            <Helmet>
              <title>National ID | API Request</title>
            </Helmet>
            <APIRequest />
          </>
        }
      />
      <Route
        path="/cardprice"
        element={
          <>
            <Helmet>
              <title>National ID | Card Price</title>
            </Helmet>
            <CardPrice />
          </>
        }
      />
      <Route
        path="/temp"
        element={
          <>
            <Helmet>
              <title>National ID | Card Price</title>
            </Helmet>
            <Temp />
          </>
        }
      />
      <Route
        path="/updates"
        element={
          <RedirectResidentPortal />
        }
      />
      <Route
        path="/update"
        element={
          <RedirectResidentPortal />
        }
      />
      <Route
        path="/cardprint"
        element={
          <CardPrintInfo />
        }
      />

      <Route
        path="/card"
        element={
          <CardPrintInfo />
        }
      />
      <Route
        path="/ethiotele"
        element={
          <RedirectEthioTelePrint />
        }
      />
      <Route
        path="/tele"
        element={
          <RedirectEthioTelePrint />
        }
      />
      <Route
        path="/print"
        element={
          <CardPrintInfo />
        }
      />
      {/* <Route
        path="/partners"
        element={
          <>
            <Helmet>
              <title>National ID | Partners</title>
            </Helmet>
           <Partners/>
          </>
        }
      /> */}
      {/* <Route
        path="/blog"
        element={
          <>
            <Helmet>
              <title>National ID | Blog</title>
            </Helmet>
            <BlogContainer/>
          </>
        }
      /> */}


      {/* <Route
        path="/users/:userId"
        element={
          <>
            <Helmet>
              <title>National ID | verify</title>
              <meta name="description" content="Helmet application" />
  </Helmet>
            <Verify />
          </>
        }
      /> */}

      {faqRoutes}

      {/* <Route
        path="/faq"
        element={
          <>
            <Helmet>
              <title>National ID | Faq</title>
              <meta name="description" content="Helmet application" />
  </Helmet>
            <Faqpage />
          </>
        }
      /> */}
    </Routes>
  );
};

export default RoutesComponent;
