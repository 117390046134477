import React, { useState, useEffect, useCallback } from "react";
import {
    MapContainer,
    TileLayer,
    Marker,
    Popup,
    Polyline,
    ZoomControl,
} from "react-leaflet";
import { useSelector } from "react-redux";
import { getLocations } from "../../redux/selectors";
import "leaflet/dist/leaflet.css";
import axios from "axios";
import location_red from "../../assets/red_location.jpg";
import location_green from "../../assets/green_location.jpg";
import { useMediaQuery } from "react-responsive";
//import ButtonDropDown from "../dropdown/buttonDropDown";
import {
    BankIcon,
    locationIcon,
    MorIcon,
    DarsIcon,
    PostOfficeIcon,
    TeleIcon,
    PalaceParkingIcon,
    CrrsaIcon,
} from "./locationIconDefinition";
import { svgIcon3, svgIcon4 } from "./locationIconDefinition";
import { MapPinned } from "lucide-react";
import { useTranslation } from "react-i18next";
const handleLocationIconToDisplay = (locationType) => {
    const typeToLowerCase = locationType?.toLowerCase();
            console.log(typeToLowerCase);

    switch (typeToLowerCase) {
        case "bank":
            return BankIcon;
        case "mor":
            return MorIcon;
        case "dars":
            return DarsIcon;
        case "post Office":
            return PostOfficeIcon;
        case "tele":
            return TeleIcon;
        case "palace parking":
            return PalaceParkingIcon;
        case "woreda":
            return CrrsaIcon;
        default:
            return locationIcon;
    }
};
const EthiopiaMap = ({
    locationsList,
    currentLocation,
    handleFetchNearestLocation,
    handleSelectedLocation,
    selectedLocation,
}) => {
    return (
        <MapContainer
            style={{ height: "100vh", width: "100wh" }}
            center={[9.03009244968558, 38.762053853992214]}
            zoom={7}
            scrollWheelZoom={false}
            zoomControl={false}
        >
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            />
            {currentLocation && (
                <Marker
                    icon={svgIcon3}
                    position={[
                        currentLocation.latitude,
                        currentLocation.longitude,
                    ]}
                >
                    <Popup autoOpen={true}>
                        <div>
                            <h3>Your Current Location</h3>
                            <p>Accuracy: {currentLocation.accuracy} meters</p>
                        </div>
                    </Popup>
                </Marker>
            )}
            {locationsList.map((location) => (
                <Marker
                    key={location.location_name}
                    icon={handleLocationIconToDisplay(location.locationType)}
                    title={location.location_name}
                    position={[location.longitude, location.latitude]}
                    eventHandlers={{
                        click: (e) => {
                            window.open(location.address, "_blank");
                        },
                    }}
                >
                    {/* <Popup>
              <b>{location.location_name}</b>
              <br />
              <a
                href={location.address}
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Maps Location
              </a>
            </Popup> */}
                </Marker>
            ))}
            <LegendControl
                position='topright'
                locations={[]}
                handleFetchNearestLocation={handleFetchNearestLocation}
                handleSelectedLocation={handleSelectedLocation}
                selectedLocation={selectedLocation}
            />
            {/* <LegendControl2 position="bottomright" /> */}
            <ZoomControl position='bottomleft' />
        </MapContainer>
    );
};

const LegendBox = ({
    handleFetchNearestLocation,
    closestLocations,
    handleSelectedLocation,
}) => {
    console.log("legendbox closest", closestLocations);
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });
    const { t } = useTranslation();

    const mobileArray = closestLocations ? closestLocations.slice(0, 3) : null;
    return (
        <>
            {closestLocations && closestLocations.length !== 0 ? (
                <div className='text-center md:text-left legend-box bg-white p-4 rounded-lg shadow-md'>
                    <h3 className='text-center md:text-left text-xl font-bold  mb-2'>
                        Closest Fayda Centers near You
                    </h3>
                    <p className='font-semibold text-md  mb-3'>
                        Click one of the following to get directions to Fayda
                        centers
                    </p>
                    <ul>
                        {isDesktopOrLaptop
                            ? closestLocations?.map((location, index) => (
                                  <li
                                      className='flex items-center mb-2  text-lg'
                                      key={location.name}
                                  >
                                      <button
                                          onClick={() =>
                                              handleSelectedLocation(location)
                                          }
                                      >
                                          <span className='w-3 h-3 rounded-full mr-2 '></span>

                                          {location.name}
                                      </button>
                                  </li>
                              ))
                            : mobileArray.map((location, index) => (
                                  <li
                                      className='flex items-center mb-2  text-lg'
                                      key={location.name}
                                  >
                                      <button
                                          onClick={() =>
                                              handleSelectedLocation(location)
                                          }
                                      >
                                          <span className='w-3 h-3 rounded-full mr-2 '></span>

                                          {location.name}
                                      </button>
                                  </li>
                              ))}
                    </ul>
                </div>
            ) : (
                <button
                    onClick={() => handleFetchNearestLocation()}
                    className='legend-box bg-white p-4 rounded-lg text-3xl shadow-md flex items-center gap-2'
                >
                    <MapPinned />
                    <p className='text-xl font-bold '>{t("fayda-near-you")} </p>
                </button>
            )}
        </>
    );
};
const LegendBoxMarker = () => {
    return (
        <div className='legend-box bg-white p-2 rounded-lg shadow-md'>
            <h3 className='text-xl   mb-2 flex items-center'>
                <img
                    src={location_green}
                    alt='Active Location Indicator'
                    className='w-10 h-10 mr-2'
                />
                Active Registration Center
            </h3>
            <h3 className='text-xl  mb-2 flex items-center'>
                <img
                    src={location_red}
                    alt='Inactive Location Indicator'
                    className='w-10 h-10 mr-2'
                />
                Coming Soon Registration Center
            </h3>
        </div>
    );
};
const LegendControl = (props) => {
    const {
        position,
        locations,
        closestLocations,
        handleSelectedLocation,
        handleFetchNearestLocation,
    } = props;

    return (
        <div className='leaflet-top leaflet-right'>
            <div className='leaflet-control legend-control'>
                <LegendBox
                    locations={locations}
                    handleFetchNearestLocation={handleFetchNearestLocation}
                    closestLocations={closestLocations}
                    handleSelectedLocation={handleSelectedLocation}
                />
            </div>
        </div>
    );
};
const LegendControl2 = (props) => {
    const { position, locations } = props;

    return (
        <div className='leaflet-bottom leaflet-right'>
            <div className='leaflet-control legend-control'>
                <LegendBoxMarker />
            </div>
        </div>
    );
};

const NewLocations = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });
    const locationsList = useSelector(getLocations);
    const [currentLocation, setCurrentLocation] = useState(null);
    // const routes = [[ 9.2132 , 36.1232 ],[9.2132 , 36.1232 ],[ 9.2132 , 36.1232 ],[ 9.2132 , 36.1232 ],[9.2132,36.1232]]
    const [routes, setRoutes] = useState([]);
    // const [routes2, setRoutes2] = useState([]);
    // const [routes3, setRoutes3] = useState([]);

    const [closest, setClosest] = useState([]);
    const [closestLocations, setClosestLocations] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState([]);
    const [drawRoute, setDrawRoute] = useState([]);
    const [zoom, setZoom] = useState(7);
    const [center, setCenter] = useState(false);
    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                setCurrentLocation({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                    accuracy: position.coords.accuracy,
                });
            },
            (error) => {
                console.error(error);
            }
        );
    }, [setCurrentLocation]);
    // useEffect(() => {
    //
    // }, [selectedLocation, currentLocation]);
    const handleFetchNearestLocation = () => {
        currentLocation &&
            axios
                .get(
                    `https://mapapi.gebeta.app/api/v1/route/getOwnedNearby?lat=${currentLocation.latitude}&lon=${currentLocation.longitude}&apiKey=${process.env.REACT_APP_MAP_API}`
                )
                .then((response) => {
                    // Extract the latitude and longitude data into an array
                    const locations = response.data.data.map((location) => [
                        location.latitude,
                        location.longitude,
                    ]);
                    const names = response.data.data.map(
                        (location) => location.name
                    );
                    setClosest(names);
                    setClosestLocations(response.data.data);
                    // Make the second API call inside the "then" block of the first call
                })
                .catch((error) => {
                    console.error(error);
                });
    };
    const handleSelectedLocation = async (userSelected) => {
        setSelectedLocation(userSelected);
        console.log("userSelected", userSelected);
        console.log("currentLocation", currentLocation);
        console.log(userSelected);
        try {
            const response = await axios.get(
                `https://mapapi.gebeta.app/api/route/direction/?origin=${currentLocation.latitude},${currentLocation.longitude}&destination=${userSelected.latitude},${userSelected.longitude}&apiKey=${process.env.REACT_APP_MAP_API}`
            );
            console.log("response", response.data.direction[0]); // Log the entire response data

            // Assuming response.data.directions is an array of arrays
            // setRoutes1(response.data.direction[0]);
            setRoutes(response.data.direction);
            // setRoutes3(response.data.direction[2]);
        } catch (err) {
            console.log(err); // Log errors to the console
        }

        setCenter(false);
        setZoom(isDesktopOrLaptop ? 16 : 7);
        console.log("HERE");
    };
    return (
        <div className='z-30 w-full'>
            {center || currentLocation == null ? (
                <EthiopiaMap
                    locationsList={locationsList}
                    currentLocation={currentLocation}
                    zoomControl={false}
                />
            ) : (
                currentLocation && (
                    <MapContainer
                        style={{
                            height: "100vh",
                            width: "100wh",
                        }}
                        center={[
                            currentLocation.latitude,
                            currentLocation.longitude,
                        ]}
                        zoom={15}
                        scrollWheelZoom={false}
                        zoomControl={false}
                    >
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                        />
                        {/* {routes.map((route, index) => (
         <Polyline pathOptions={{ color: 'red' }} positions={route} weight={5} />
      ))} */}
                        {
                            <>
                                {routes != [] && (
                                    <Polyline
                                        pathOptions={{ color: "red" }}
                                        positions={routes}
                                        weight={5}
                                    />
                                )}
                                {/* {routes?.map((route) => (
                                    <Polyline
                                        pathOptions={{ color: "red" }}
                                        positions={route}
                                        weight={5}
                                    />
                                ))}
                                
                                {routes?.map(
                                    (route) =>
                                        route.positions && (
                                            <div key={route}>
                                                <Polyline
                                                    pathOptions={{
                                                        color: "red",
                                                    }}
                                                    positions={route.positions}
                                                    weight={5}
                                                />
                                            </div>
                                        )
                                )} */}
                                {/* {routes1.length != [] && (
                                    <Polyline
                                        pathOptions={{ color: "red" }}
                                        positions={routes1}
                                        weight={5}
                                    />
                                )}
                                {routes2.length != [] && (
                                    <Polyline
                                        pathOptions={{ color: "red" }}
                                        positions={routes1}
                                        weight={5}
                                    />
                                )}
                                {routes3.length != [] && (
                                    <Polyline
                                        pathOptions={{ color: "red" }}
                                        positions={routes2}
                                        weight={5}
                                    />
                                )} */}
                            </>
                        }
                        <>
                            <Marker
                                icon={svgIcon3}
                                position={[
                                    currentLocation.latitude,
                                    currentLocation.longitude,
                                ]}
                            >
                                <Popup autoOpen={true}>
                                    <div>
                                        <h3>Your Current Location</h3>
                                        <p>
                                            Accuracy: {currentLocation.accuracy}{" "}
                                            meters
                                        </p>
                                    </div>
                                </Popup>
                            </Marker>
                            {selectedLocation.latitude &&
                                selectedLocation.longitude && (
                                    <Marker
                                        icon={svgIcon4}
                                        position={[
                                            selectedLocation.latitude,
                                            selectedLocation.longitude,
                                        ]}
                                    ></Marker>
                                )}
                            {locationsList.map((location) => (
                                <Marker
                                    key={location.location_name}
                                    icon={handleLocationIconToDisplay(
                                        location.locationType
                                    )}
                                    position={[
                                        location.longitude,
                                        location.latitude,
                                    ]}
                                    eventHandlers={{
                                        click: (e) => {
                                            window.open(
                                                location.address,
                                                "_blank"
                                            );
                                        },
                                    }}
                                ></Marker>
                            ))}
                            <LegendControl
                                position='topright'
                                locations={closest}
                                closestLocations={closestLocations}
                                selectedLocation={selectedLocation}
                                handleFetchNearestLocation={
                                    handleFetchNearestLocation
                                }
                                handleSelectedLocation={handleSelectedLocation}
                            />
                            {/* <LegendControl2 position="bottomright" /> */}
                            <ZoomControl position='bottomleft' />
                        </>
                    </MapContainer>
                )
            )}
        </div>
    );
};

export default NewLocations;
