import React from "react";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import Update from "../../../../assets/update.png";

const Updatenumber = ({ id }) => {
    const { t } = useTranslation();
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });

    return (
        <>
            {isDesktopOrLaptop ? (
                <div
                    id={id}
                    class='relative flex bg-update-services bg-left-top bg-cover h-fit w-full  items-center justify-center my-5 gap-10 '
                >
                    <div className='flex items-center justify-center w-[70%] pt-10'>
                        <img
                            src={Update}
                            alt='Logo'
                            className='items-start align-top'
                        />
                    </div>
                    <div
                        id='update-fayda'
                        className='flex flex-col items-start mt-1 pr-20  w-[80%]'
                    >
                        <h1 className='mt-3 mb-3 text-6xl font-medium text-white '>
                            {t("services-update-fayda-text")}
                        </h1>

                        <p className='text-2xl font-normal text-justify text-white mt-5 '>
                            {t("services-update-fayda-text2")}
                        </p>

                        <div className=' flex gap-10 text-xl'>
                            {/* <h2 className='relative w-full py-6 text-2xl font-medium text-center border-2 rounded-full  border-MetallicBlue text-MetallicBlue'>Learn More </h2> */}

                            <a
                                href='https://resident.fayda.et'
                                className='my-4 w-fit px-20 font-semibold text-white/90 text-center shadow-md border-2  border-white/40 py-2 bg-transparent  rounded-3xl  hover:bg-[#083247] transition-all transform duration-300'
                            >
                                {t("update-now")}
                            </a>
                        </div>
                    </div>
                </div>
            ) : (
                //**********************************************************Mobile Version **********************************************************
                <div
                    id={id}
                    class=' flex flex-col h-92 items-center bg-update-services bg-left-top bg-cover  my-2 gap-5 justify-center px-8  pb-8  '
                >
                    <div className='flex flex-col items-center justify-center w-full h-full pt-5 text-left'>
                        <h1 className='mt-3 mb-2 text-4xl font-medium text-white '>
                            {t("services-update-fayda-text")}
                        </h1>

                        <p className='text-xl text-justify  opacity-80 text-white'>
                            {t("services-update-fayda-text2")}
                        </p>
                    </div>

                    <div className='flex items-center justify-center w-full h-full'>
                        {/* <h2 className='relative w-full py-6 text-2xl font-medium text-center border-2 rounded-full  border-MetallicBlue text-MetallicBlue'>Learn More </h2> */}

                        <a
                            href='https://resident.fayda.et'
                            className=' py-2 px-20 w-fit text-xl transition-all cursor-pointer duration-300 transform border-2 rounded-md shadow-lg text-center hover:bg-MetallicBlue border-/40 hover:text-white ease-in-out text-white'
                        >
                            {t("update-now")}
                        </a>
                    </div>

                    <div className='flex items-center justify-center w-full'>
                        <img
                            src={Update}
                            alt='Logo'
                            className='items-start align-top'
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default Updatenumber;
