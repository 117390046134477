import React, { useState, useEffect } from "react";
import HeroLandingPage from "./Components/hero/heroLandingPage";
import SmallFooter from "../../components/footer/smallFooter";
import Body from "./Components/body/Body";

function CardPrintInfo() {
    const [citizen, setCitizen] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <HeroLandingPage citizen={citizen} />
            <Body />
            <SmallFooter />
        </>
    );
}

export default CardPrintInfo;
