import React from "react";
import { useTranslation } from "react-i18next";
// import { HashLink } from 'react-router-hash-link';
import HelpIcon from "../../../../assets/pre-register-icon.svg";
import GetFaydaIcon from "../../../../assets/get-fayda.png";
import CardPrintIcon from "../../../../assets/card-print.png";
import UpdateFaydaIcon from "../../../../assets/update-fayda-icon.svg";
import FaydaAppIcon from "../../../../assets/get-fayda-app-icon.svg";
import { useMediaQuery } from "react-responsive";
const Hero = () => {
    const { t } = useTranslation();
    const handleClickScroll = (id) => {
        const element = document.getElementById(id);
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: "smooth", block: "end" });
        }
    };

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });
    const heroCardItems = [
        {
            icon: GetFaydaIcon,
            alt: "GetFaydaIcon",
            title: t("services-get-no"),
            scrollTo: "get-no",
        },
        {
            icon: CardPrintIcon,
            alt: "CardPrintIcon",
            title: t("services-card-print"),
            scrollTo: "get-card",
        },
        {
            icon: UpdateFaydaIcon,
            alt: "UpdateFaydaIcon",
            title: t("services-update-no"),
            scrollTo: "update-fayda",
        },
        {
            icon: HelpIcon,
            alt: "HelpIcon",
            title: t("services-pre-reg"),
            scrollTo: "help",
        },
        {
            icon: FaydaAppIcon,
            alt: "FaydaAppIcon",
            title: t("services-get-app"),
            scrollTo: "mobile-app",
        },
    ];
    return (
        <>
            <div class=' bg-id-services-hero  bg-cover h-full w-full'>
                <div class=' bg-top-bot-gradient'>
                    {/************************** HERO TITLE ************************* */}
                    <div className='grid  w-full h-full gap-8 px-5 pb-10 md:px-16 md:align-middle pt-20'>
                        <div className='flex flex-wrap justify-center md:justify-start lg:px-32 md:pt-[16%] pt-[30%] text-3xl md:text-8xl w-full'>
                            <h1 className='pr-2 mb-0 font-extrabold text-white '>
                                {t("services-title1")}
                            </h1>
                            <h1 className='mb-0 ml-2 font text-green '>
                                {t("services-title2")}{" "}
                            </h1>
                        </div>
                    </div>

                    <div className='grid w-full h-fit grid-cols-3 lg:grid-cols-5 gap-1 px-5 pb-10 md:px-16 lg:px-32 md:align-middle md:gap-8'>
                        {heroCardItems.map((cardItem, index) => (
                            <div className='relative w-full flex flex-col justify-center items-center p-2'>
                                <div
                                    className='cursor-pointer hover:scale-110 transition-all duration-300 shadow-lg shadow-lightblue flex flex-col justify-center text-center bg-white w-full md:w-[90%] min-h-[150px] h-[150px] rounded-lg items-center hover:bg-green font-semibold text-MetallicBlue'
                                    onClick={() =>
                                        handleClickScroll(cardItem.scrollTo)
                                    }
                                >
                                    {/* Image with consistent dimensions */}
                                    <img
                                        src={cardItem.icon}
                                        alt={cardItem.alt}
                                        className='w-[80px] h-[80px] object-contain'
                                    />
                                    {/* Divider line */}
                                    <div className='w-[70%] border-b-2 border-lightblue my-2'></div>
                                    {/* Title */}
                                    <h1 className='px-4 text-xs font-bold leading-tight md:text-xl'>
                                        {cardItem.title}
                                    </h1>
                                </div>
                                {/* Vertical divider */}
                                {index !== heroCardItems.length - 1 && (
                                    <div className='absolute right-[-10px] top-1/2 transform -translate-y-1/2 h-[40%] w-[1px] bg-white'></div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Hero;
